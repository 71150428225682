<template>
  <div class="overflow-scroll bg-gray-200">
    <div
      class="fixed z-10 w-full flex justify-between items-center px-8 border-b border-black bg-white"
    >
      <div>EWH.Admin - Dokumente</div>
      <div class="right flex">
        <div @click="$router.back()" class="py-2 mr-2">
          <RoundButton color="orange" icon="prev" text="Zurück" />
        </div>
        <div @click="$router.push('/admin')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div class="pt-24 px-8 pb-16">
      <!--label class="text-xs font-bold">Aktionen</label>
      <div class="p-4 bg-white rounded-lg mb-4">
        <button
          @click="fixArticles"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
        >
          Fix article tags
        </button>
      </div-->
      <div class="">
        <p>Ausgewählte Dokumente: {{ selectionCount }}</p>
        <div
          :class="`${selectionCount === 0 ? 'opacity-25' : ''}`"
          class="mb-8"
        >
          <button
            @click="deleteSelection"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
            :disabled="isSelectionEmpty"
          >
            Dokumente löschen
          </button>

          <button
            @click="removeTags"
            :disabled="isSelectionEmpty"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
          >
            Tag(s) entfernen
          </button>
          <button
            @click="addTags"
            :disabled="isSelectionEmpty"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-600"
          >
            Tag(s) hinzufügen
          </button>
          <input type="text" v-model="tagsString" ref="tagsInput" />
        </div>
      </div>
      <div>
        <v-server-table
          ref="articlesTable"
          :columns="columns"
          :options="options"
          @select="onSelect"
        >
          <!--div slot="filter__year">
            <select>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </select>
            <input type="checkbox" class="form-control" />
          </div-->
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import axios from "axios"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL

import articleLink from "@/components/AdminArticleLink.vue"

import { createInstance, resource } from "vuex-pagination"

//import Article from '@/models/article';

//import { bus } from "@/main";

export default {
  name: "archive",
  components: {
    RoundButton,
  },
  data() {
    return {
      selectionCount: 0,
      selectedTags: [],
      tagsString: "",
      columns: [
        "name",
        "status",
        "type",
        "year",
        "month",
        "day",
        "tags",
        "link",
        "created",
      ],
      tableData: [],
      options: {
        uniqueKey: "_id",
        columnsDropdown: true,
        columnsDisplay: {
          year: "mobile",
          month: "mobile",
          day: "mobile",
        },
        templates: {
          name: articleLink,
          tags: function(h, row, index) {
            return (
              <div class="text-xs">{row.tags.join(",").toUpperCase()} </div>
            )
          },
        },
        async requestFunction(data) {
          try {
            const response = await axios.get(`${API_URL}admin_articles`, {
              headers: authHeader(),
              params: { ...data },
            })

            const tmpData = response.data
            response.data.data = tmpData.data.map(item => {
              item.created = moment(item.created).format("DD.MM.YYYY HH:mm")
              return item
            })

            return response.data
          } catch (error) {
            console.log(error)
            this.$router.push("/login")
          }
        },
        columnsClasses: {
          name: "text-left",
          type: "text-left",
          year: "text-left",
          month: "text-left",
          day: "text-left",
          tags: "text-left",
          created: "text-left",
          link: "text-left",
          status: "text-left",
        },
        filterByColumn: true,
        filterable: [
          "name",
          "status",
          "type",
          "year",
          "month",
          "day",
          "created",
          "tags",
          "link",
        ],

        perPage: 25,
        perPageValues: [10, 25, 50, 100],
        pagination: {
          chunk: 10,
          show: true,
          dropdown: false,
          edge: true,
          align: "center",
        },
        dateColumns: ["created"],
        //toMomentFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        dateFormat: "DD.MM.YYYY",
        selectable: {
          mode: "multiple", // or 'multiple'
          only: function(row) {
            return true // any condition
          },
          selectAllMode: "page", // or 'page',
          programmatic: false,
        },
        texts: {
          loadingError: "Ein Fehler ist aufgetreten.",
          count:
            "Zeige {from} bis {to} von {count} Dokumenten|{count} Dokumente|einem Dokument",
          first: "Anfang",
          last: "Ende",
          filter: "Filter:",
          filterPlaceholder: "Search query",
          limit: "Listen-Einträge:",
          page: "Seite:",
          noResults: "Keine Dokumente gefunden",
          noRequest: "Please select at least one filter to fetch results",
          filterBy: "Nach {column}",
          loading: "Lade...",
          defaultOption: "Select {column}",
          columns: "Spalten",
        },
      },
    }
  },
  computed: {
    isSelectionEmpty() {
      return this.$refs.articlesTable?.selectedRows?.length === 0
    },
  },
  created() {},
  updated() {},
  async mounted() {},
  methods: {
    onSelect(selected) {
      this.selectionCount = selected.length
    },

    async deleteSelection() {
      const selected = this.$refs.articlesTable.selectedRows
      if (selected.length === 0) return
      if (!confirm(`${selected.length} Dokumente löschen, sicher?`)) return

      const response = await axios.delete(
        `${API_URL}admin_articles_batch_delete`,
        {
          data: { articleIds: selected.map(s => s._id) },
          headers: authHeader(),
        }
      )
      if (response.status === 204) {
        this.$refs.articlesTable.resetSelectedRows()
        this.$refs.articlesTable.refresh()
      }
    },

    async addTags() {
      const selected = this.$refs.articlesTable.selectedRows
      if (selected.length === 0) return
      if (this.tagsString === "") {
        alert("Bitte Tag(s) eingeben. Mehrere Tags mit Leerzeichen trennen.")
        this.$refs.tagsInput.focus()
        return
      }

      const tagsToAdd = this.tagsString
        .trim()
        .split(" ")
        .map(ts => ts.toUpperCase())

      if (
        confirm(
          `${tagsToAdd.length} ${
            tagsToAdd.length === 1 ? "Tag" : "Tags"
          } (${tagsToAdd.join(",")}) zu ${
            selected.length
          } Dokumenten hinzufügen?`
        )
      ) {
        const postData = {
          tags: tagsToAdd,
          articleIds: selected.map(s => s._id),
        }
        const response = await axios.post(
          `${API_URL}admin_articles_addtags`,
          postData,
          {
            headers: authHeader(),
          }
        )
        debugger
        if (response.status === 204) {
          this.$refs.articlesTable.resetSelectedRows()
          this.$refs.articlesTable.refresh()
        }
      }
    },
    async fixArticles() {
      const response = await axios.post(
        `${API_URL}admin_articles_fix_tags`,
        {},
        {
          headers: authHeader(),
        }
      )
    },
    async removeTags() {
      const selected = this.$refs.articlesTable.selectedRows
      if (selected.length === 0) return
      if (this.tagsString.trim() === "") {
        alert("Bitte Tag(s) eingeben. Mehrere Tags mit Leerzeichen trennen.")
        this.$refs.tagsInput.focus()
        return
      }
      const tagsToRemove = this.tagsString
        .trim()
        .split(" ")
        .map(ts => ts.toUpperCase())

      if (
        confirm(
          `${tagsToRemove.length} ${
            tagsToRemove.length === 1 ? "Tag" : "Tags"
          } (${tagsToRemove.join(",")}) von ${
            selected.length
          } Dokumenten entfernen?`
        )
      ) {
        try {
          const response = await axios.delete(
            `${API_URL}admin_articles_removetags`,
            {
              data: {
                articleIds: selected.map(s => s._id),
                tags: tagsToRemove,
              },
              headers: authHeader(),
            }
          )
          if (response.status === 204) {
            this.$refs.articlesTable.resetSelectedRows()
            this.$refs.articlesTable.refresh()
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
}
</script>
<style lang="scss">
li.VuePagination__pagination-item a {
  width: auto !important;
}
div.VuePagination.is-centered {
  justify-content: center;
}
p.VuePagination__count {
  justify-content: center;
}
</style>
